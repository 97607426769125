/* eslint-disable react-hooks/exhaustive-deps */
import { STORAGE, getLocalStorage } from "Utils/storage";
import { useEffect } from "react"
import { useHistory } from "react-router-dom";

export const AdminPage = () => {
    const history = useHistory()
    useEffect(() => {
        const token = (getLocalStorage(STORAGE.ADMIN_TOKEN) || null)
        if (!token) {
            history.push({
                pathname: `/admin/login`,
            })
        }
        else {
            history.push({
                pathname: `/admin/import_flow`,
            })
        }
    },[])
}