/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FilterAction,
  Wrap,
  WrapperLabel,
  ButtonSearch,
  ItemInput,
  SelectItem,
  SelectItemLarge,
} from "./styled";
import { Form, Pagination, Select, Table } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { SearchOutlined } from "@ant-design/icons";
import { FormInput, Notification } from "Components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getListValidSchool } from "Apis/admin";
import { TableContainer } from "Layouts/Admin/styled";
import { formatDate, getLabelByValue } from "Utils/convertValue";
import { useDispatch, useSelector } from "react-redux";
import actions from "Store/Actions";
import moment from "moment";
import ModalDetailAdmin from "../ModalDetail/ModalDetailAdmin";
import { textNoData } from "Utils/data-default";
import { ERROR_API_MESSAGE } from "Constants";
const { getUniversityId } = actions;
const HomeAdmin = () => {
  const [total, setTotal] = useState(50);
  const [pageSize, setPageSize] = useState(10);
  const [resetPage, setResetPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [loadding, setLoadding] = useState(false);
  const [dataSearch, setDataSearch] = useState({})
  const [listValidSchool, setListValidSchool] = useState([]);
  const { dataVersion, dataGroupSchool, dataUniversityID} = useSelector((state) => state.admin);
  const [openDetailModal, setOpenDetaiModal] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [isOpen, setIsOpen] = useState({
    university_code: false,
    university_group_id:false,
    have_faculty_of_medicine:false,
    version:false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUniversityId({valid:1},(action, res) => { })); 
  },[dispatch])
  const { getVersion } = actions;
  const optionsYesNo = [
    { label: "有", value: '1' },
    { label: "無", value: '0' },
  ];

  useEffect(() => {
    !dataVersion.length && dispatch(getVersion({
      table: "AI_ASPIRATION_GROUP"
    }, (action, res) => {}));
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoadding(true);
      let dataFilter = {};
      if (dataSearch) {
        for (let key in dataSearch) {
          if (dataSearch.hasOwnProperty(key) && dataSearch[key] !== '' && dataSearch[key] !== undefined) {
            dataFilter[key] = dataSearch[key];
          }
        }
        }
      try {
        const data = await getListValidSchool({
          page: currentPage-1,
          size: pageSize,
          ...dataFilter
        });
        const result = data.data;
        setTotal(result.total);
        setListValidSchool(
          result.items.map((item, index) => ({ ...item, key: index }))
        );
        setLoadding(false);
      } catch (error) {
        setLoadding(false);
        Notification.error(
          ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] ||
            error.message ||
            error.errors?.[0].msg
        )
      }
    }
    fetchData();
  }, [pageSize, currentPage, resetPage]);

  const columns = [
    {
      title: "連番",
      render: (text, record, index) =>(
        <span className="id_record" onClick={() => handleShowDetail(record)}>
          {(currentPage - 1) * pageSize + index + 1}
        </span>
        ),
      key: "stt",
    },
    {
      title: "大学コード",
      dataIndex: "大学コード",
      key: "大学コード",
    },
    {
      title: "大学名",
      dataIndex: "大学名",
      key: "大学名",
    },
    {
      title: "グループ",
      dataIndex: "グループ",
      key: "グループ",
    },
    {
      title: "医学部",
      dataIndex: "医学部",
      key: "医学部",
      render: (value) => <span>
        {getLabelByValue(optionsYesNo,String(value))}
      </span>
    },
    {
      title: "バージョン",
      dataIndex: "Version",
      key: "Version",
    },
    {
      title: "インポート日付",
      dataIndex: "IMPORT_AT",
      key: "IMPORT_AT",
      className:'min-150',
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];

  const validationSchema = yup
    .object({
      university_code: yup.string(),
      university_group_id: yup.string(),
      have_faculty_of_medicine: yup.string(),
      university_name: yup.string(),
      version: yup.number(),
      import_from: yup.string().test('check-import-to', '開始日は終了日よりの前に日付を選択してください', function(value) {
        const fromDate =  moment(value, "DD/MM/YYYY");
        const toDate = moment(this.parent.import_to, "DD/MM/YYYY");
        return  fromDate < toDate || !this.parent.import_to || !value;
      }),
      import_to: yup.string(),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setValue,
  } = form;

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onSubmit = (value) => {
    setDataSearch(value)
    setCurrentPage(1)
    setResetPage((pre)=>pre+1)
  };
  const handleShowDetail = async (data) => {
    setOpenDetaiModal(true)
    const detail = {
      "大学コード": data?.大学コード,
      "大学名" : data?.大学名,
      "グループ" : data?.グループ,
      "医学部" : getLabelByValue(optionsYesNo,String(data?.医学部)) ,
      "バージョン": data?.Version,
      "インポート日付" : formatDate(data?.IMPORT_AT)
    }
    setDataDetail(detail)
  };
  const handleSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <Wrap>
      <FormProvider {...form}>
        <form id="information-form" autoComplete="off">
          <FilterAction>
            <SelectItem>
              <Form.Item label={<WrapperLabel>大学コード</WrapperLabel>}>
              <Select
                  className="select_type"
                  name="university_code"
                  showSearch
                  options={dataUniversityID}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("university_code", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        university_code: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.university_code}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      university_code: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItem>
            <SelectItem>
              <Form.Item label={<WrapperLabel>グループ</WrapperLabel>}>
              <Select
                  className="select_type"
                  name="university_group_id"
                  showSearch
                  options={dataGroupSchool}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={(e,option) => setValue("university_group_id", option?.label)
                  }
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        university_group_id: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.university_group_id}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      university_group_id: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItem>
            <SelectItem>
              <Form.Item label={<WrapperLabel>医学部</WrapperLabel>}>
              <Select
                  className="select_type"
                  name="have_faculty_of_medicine"
                  showSearch
                  options={optionsYesNo}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("have_faculty_of_medicine", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        have_faculty_of_medicine: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.have_faculty_of_medicine}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      have_faculty_of_medicine: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItem>
          </FilterAction>
          <FilterAction>
            <ItemInput>
              <FormInput label="大学名" placeholder='例）東京大学' name="university_name" type="text" onPressEnter={handleSelectKeyDown} allowClear/>
            </ItemInput>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>バージョン</WrapperLabel>}>
                <Select
                  className="select_type"
                  options={dataVersion}
                  name="version"
                  showSearch
                  allowClear
                  onChange={e => setValue("version", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        version: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.version}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      version: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <ButtonSearch onClick={handleSubmit(onSubmit)}>
              <SearchOutlined />
              検索
            </ButtonSearch>
          </FilterAction>
        </form>
      </FormProvider>
      <TableContainer>
        <Table
          columns={columns}
          loading={loadding}
          dataSource={listValidSchool}
          pagination={false}
          scroll={{ y: 440, x: true }}
          locale={{
            emptyText: textNoData,
          }}
        />
        {listValidSchool?.length > 0 && (
          <Pagination
            style={{ marginTop: "16px", textAlign: "right" }}
            total={total}
            current={currentPage}
            onChange={handlePageChange}
            showSizeChanger={true}
            pageSize={pageSize}
            locale={{
              items_per_page: "/ ページ",
            }}
          />
        )}
      </TableContainer>
      <ModalDetailAdmin
      visibleModal={openDetailModal}
      onCancel={() => setOpenDetaiModal(false)}
      onOk={() => setOpenDetaiModal(false)}
      dataDetail={dataDetail}
      />
    </Wrap>
  );
};

export default HomeAdmin;
