/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FilterAction, Wrap, ButtonDelete, ItemInput } from "./styled";
import { FormProvider, useForm } from "react-hook-form";
import {} from "@ant-design/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "./ModalConfirm";
import { FormInput } from "Components";

const DeleteScheduled = () => {
  const [valueId, setValueId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const validationSchema = yup.object({
    studentID: yup.string(),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, setValue ,watch} = form;
  const onSubmit = async (data) => {
    setValueId(data?.studentID);
    setOpenModal(true);
  };
  const studentIdDelete = watch('studentID')
  const onChange = (e) => {
    setValue("studentID", e.target.value);
    setValueId(e.target.value);
  };
  const clearData = () => {
    setValue("studentID", undefined);
    setValueId();
  };
  const handleOnInput = (e) => {
    const number = /^\d*$/g;
    const notNumber = /\D/g;
    if(number.test(e.target.value)) {
      return true;
    }
    
    e.target.value = e.target.value.replace(notNumber, '')
    return false;
  };

  return (
    <Wrap>
      <FormProvider {...form}>
        <form id="information-form" autoComplete="off">
          <FilterAction>
            <ItemInput>
              <FormInput
                label="生徒ID"
                placeholder="生徒IDを入力してください。 "
                name="studentID"
                type="text"
                maxLength={8}
                onInput={handleOnInput}
                // value={valueId}
                onChange={(e) => onChange(e)}
                allowClear
              />
            </ItemInput>

            <ButtonDelete
              disabled={studentIdDelete?.length !== 8}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
            >
              削除
            </ButtonDelete>
          </FilterAction>
        </form>
      </FormProvider>
      <ModalConfirm
        visibleModal={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={() => setOpenModal(false)}
        valueId={valueId}
        clearData={clearData}
      />
    </Wrap>
  );
};

export default DeleteScheduled;
