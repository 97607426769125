import React from "react";
import {
  ActionButton,
  ButtonCancel,
  ButtonImport,
  ModalComponentWrapper,
  TitleDelete,
} from "./styled";
import { deleteScheduled } from "Apis/admin";
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from "Constants";
import { Notification } from "Components";

const ModalConfirm = ({ onOk, onCancel, visibleModal, valueId,clearData }) => {
  const handleOK = async () => {
    try {
      const data = await deleteScheduled({
        studentID: valueId || "",
      });
      const res = data.data;
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        Notification.success("対象の受講予定講座を削除しました。");
        clearData()
      }
    } catch (error) {
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          error.message ||
          error.errors?.[0].msg
      );
    }
    onOk()
  };
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
    >
      <TitleDelete>{valueId ?? valueId}生徒IDの受講予定講座を削除してもよろしいですか？</TitleDelete>
      <ActionButton>
        <ButtonImport onClick={handleOK}>削除する</ButtonImport>
        <ButtonCancel onClick={onCancel}>キャンセル</ButtonCancel>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalConfirm;
